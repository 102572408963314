import { combineReducers } from 'redux';
import auth, { authSaga } from './auth';
import loading from './loading';
import popup from './popup';
import { all } from 'redux-saga/effects';

const rootReducer = combineReducers({ auth, loading, popup });

export function* rootSaga() {
  yield all([authSaga()]);
}

export default rootReducer;
