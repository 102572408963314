import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { getStudInfo, getStudInfoFromP } from '../api/methods';

//ACTION TYPE
const AUTH_ERROR = 'auth/AUTH_ERROR';
const SET_P = 'auth/SET_P';
const [STUDINFO_FROM_TOKEN, STUDINFO_FROM_TOKEN_SUCCESS, STUDINFO_FROM_TOKEN_FAILURE] =
  createRequestActionTypes('auth/STUDINFO_FROM_TOKEN');
const [STUDINFO_FROM_P, STUDINFO_FROM_P_SUCCESS, STUDINFO_FROM_P_FAILURE] = createRequestActionTypes('auth/STUDINFO_FROM_P');

//CREATE ACTION
export const setAuthError = createAction(AUTH_ERROR);
export const studInfoFromToken = createAction(STUDINFO_FROM_TOKEN, ({ token }) => ({ token }));
export const studInfoFromP = createAction(STUDINFO_FROM_P, ({ p }) => ({ p }));
export const setP = createAction(SET_P, (p) => p);
//CREATE ACTION SAGA
const studInfoFromTokenSaga = createRequestSaga(STUDINFO_FROM_TOKEN, getStudInfo);
const studInfoFromPSaga = createRequestSaga(STUDINFO_FROM_P, getStudInfoFromP);
//BOOKPICK SAGA
export function* authSaga() {
  yield takeLatest(STUDINFO_FROM_TOKEN, studInfoFromTokenSaga);
  yield takeLatest(STUDINFO_FROM_P, studInfoFromPSaga);
}

//STATE
const initailState = {
  auth: null,
  authError: null,
  p: null,
};

//REDUCER
const auth = handleActions(
  {
    [STUDINFO_FROM_TOKEN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth,
    }),
    [STUDINFO_FROM_TOKEN_FAILURE]: (state, { payload: auth }) => ({
      ...state,
      authError: true,
    }),
    [STUDINFO_FROM_P_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      auth,
    }),
    [STUDINFO_FROM_P_FAILURE]: (state, { payload: auth }) => ({
      ...state,
      authError: true,
    }),
    [SET_P]: (state, { payload: p }) => ({
      ...state,
      p,
    }),
    [AUTH_ERROR]: (state) => ({
      ...state,
      authError: true,
    }),
  },
  initailState,
);

export default auth;
