import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ALERT_POPUP_TYPE, CONFIRM_POPUP_TYPE } from '../../lib/createPopup';
import { clearPopup } from '../../modules/popup';
import AlertPopup from './AlertPopup';
import ConfirmPopup from './ConfirmPopup';

const PopupContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearPopup());
    return () => {
      dispatch(clearPopup());
    };
  }, [dispatch]);

  const { message, btnYesText, btnNoText, type, isOpen, onClickYes, onClickNo } = useSelector(
    ({ popup }) => ({
      message: popup.message,
      btnYesText: popup.btnYesText,
      btnNoText: popup.btnNoText,
      type: popup.type,
      isOpen: popup.isOpen,
      onClickYes: popup.onClickYes,
      onClickNo: popup.onClickNo,
    }),
    shallowEqual,
  );

  const onYesHandler = useCallback(() => {
    dispatch(clearPopup());
    if (onClickYes) {
      onClickYes();
    }
  }, [dispatch, onClickYes]);

  const onNoHandler = useCallback(() => {
    dispatch(clearPopup());
    if (onClickNo) {
      onClickNo();
    }
  }, [dispatch, onClickNo]);

  // useEffect(() => {
  // if (isOpen) {
  //   const body = document.getElementsByTagName('body')[0];
  //   body.classList.add('scrollLock');
  // } else {
  //   const body = document.getElementsByTagName('body')[0];
  //   body.classList.remove('scrollLock');
  // }
  // }, [isOpen]);

  if (window.Android) {
    switch (type) {
      case ALERT_POPUP_TYPE:
        window.AlertfuncOK = onYesHandler;
        window.Android.alert(message, 'AlertfuncOK()', btnYesText);
        break;
      case CONFIRM_POPUP_TYPE:
        window.funcYes = onYesHandler;
        window.funcNo = onNoHandler;
        window.Android.confirm(message, 'funcYes()', 'funcNo()', btnYesText, btnNoText);
        break;
      default:
        break;
    }
    return <div className="share-pop-ui"></div>;
  }

  switch (type) {
    case ALERT_POPUP_TYPE:
      return <AlertPopup onYesHandler={onYesHandler} btnYesText={btnYesText} message={message} isOpen={isOpen}></AlertPopup>;
    case CONFIRM_POPUP_TYPE:
      return (
        <ConfirmPopup
          onYesHandler={onYesHandler}
          onNoHandler={onNoHandler}
          btnYesText={btnYesText}
          btnNoText={btnNoText}
          message={message}
          isOpen={isOpen}
        ></ConfirmPopup>
      );
    default:
      return <div className="share-pop-ui"></div>;
  }
};

export default PopupContainer;
