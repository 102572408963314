import { call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from '../modules/loading';
import { openAlertPopup } from '../modules/popup';
import { ALERT_POPUP_TYPE, exitPage } from './createPopup';

export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

export default function createRequestSaga(type, reuqest) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return function* (action) {
    console.group(`[${type}]`);
    console.log(`[${type} START]`);
    console.log(`[${type} ACTION] `, action);
    yield put(startLoading(type));
    try {
      const response = yield call(reuqest, action.payload);
      console.log(`[${type} RESPONSE]`, response);
      if (response.status === 200 && response.data.msg.resultCode === '0000') {
        yield put({
          type: SUCCESS,
          payload: response.data.data,
          //payload : action.payload,
          meta: response,
        });
      } else {
        switch (response.data.msg.resultCode) {
          case '1100':
            console.log('필수값 누락');
            break;
          case '1200':
            console.log('Length 오류');
            break;
          case '1310':
            console.log('DataType 불일치 (Integer)');
            break;
          case '1320':
            console.log('월간/주간 구분 파라미터 오류');
            break;
          case '1330':
            console.log('DataType 불일치 (Date)');
            break;
          case '1340':
            console.log('숫자범위 오류');
            break;
          case '1350':
            console.log('과목명 오류');
            break;
          case '2000':
            console.log('데이터 없음');
            break;
          case '9000':
            console.log('외부API 호출오류');
            break;
          case '9999':
            console.log('시스템 오류, 기타 오류');
            break;
          default: {
            console.log('알 수 없는 오류입니다. [' + response.data.msg.resultCode + ']');
          }
        }
        const popup = {
          type: ALERT_POPUP_TYPE,
          message: '인터넷 연결에 실패했습니다.',
          isOpen: true,
          onClickYes: () => {
            //exitPage();
          },
          btnYesText: '확인',
        };
        yield put(openAlertPopup(popup));
      }
      console.log(`[${type} SUCCESS]`);
    } catch (e) {
      const popup = {
        type: ALERT_POPUP_TYPE,
        message: '인터넷 연결에 실패했습니다.',
        isOpen: true,
        onClickYes: () => {
          //exitPage();
        },
        btnYesText: '확인',
      };
      yield put(openAlertPopup(popup));
      yield put({
        type: FAILURE,
        //payload : e,
        payload: action.payload,
        error: true,
      });
      console.log(e);
      console.log(`[${type} FAILURE]`);
    }
    console.log(`[${type} END]`);
    console.groupEnd();
    yield put(finishLoading(type));
  };
}
