import { useRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { lazy, Suspense } from 'react';

const HomeLevel = lazy(() => import('../components/HomeLevel/HomeLevel'));
const Challenge = lazy(() => import('../components/Challenge/Challenge'));
const HomeLog = lazy(() => import('../components/HomeLog/HomeLog'));
const Error = lazy(() => import('../components/Error/Error'));
const HomeLogAward = lazy(() => import('../components/HomeLog/HomeLogAward'));
const HomeLogCreate = lazy(() => import('../components/HomeLog/HomeLogCreate'));
const HomeLogCreatePreview = lazy(() => import('../components/HomeLog/HomeLogCreatePreview'));
const HomeLogDetail = lazy(() => import('../components/HomeLog/HomeLogDetail'));
const HomeLogPreview = lazy(() => import('../components/HomeLog/HomeLogPreview'));
function AppRouter() {
  const routes = useRoutes([
    {
      path: '/homelevel',
      element: (
        <ProtectedRoute>
          <HomeLevel />
        </ProtectedRoute>
      ),
    },
    {
      path: '/challenge',
      element: (
        <ProtectedRoute>
          <Challenge />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelog',
      element: (
        <ProtectedRoute>
          <HomeLog />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelogAward',
      element: (
        <ProtectedRoute>
          <HomeLogAward />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelogCreate',
      element: (
        <ProtectedRoute>
          <HomeLogCreate />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelogDetail',
      element: (
        <ProtectedRoute>
          <HomeLogDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelogPreview',
      element: (
        <ProtectedRoute>
          <HomeLogPreview />
        </ProtectedRoute>
      ),
    },
    {
      path: '/homelogCreatePreview',
      element: (
        <ProtectedRoute>
          <HomeLogCreatePreview />
        </ProtectedRoute>
      ),
    },
    { path: '/*', element: <Error /> },
  ]);

  return <Suspense fallback={<div></div>}>{routes}</Suspense>;
}

export default AppRouter;
