import { createAction, handleActions } from 'redux-actions';

const START_LOADING = 'loading/START_LOADING';
const FINISH_LOADING = 'loading/FINISH_LOADING';
const CLEAR_LOADING = 'loading/CLEAR_LOADING';
const CLEAR_ALL_LOADING = 'loading/CLEAR_ALL_LOADING';
export const startLoading = createAction(START_LOADING, (requestType) => requestType);

export const finishLoading = createAction(FINISH_LOADING, (requestType) => requestType);

export const clearLoading = createAction(CLEAR_LOADING, (requestType) => requestType);

export const clearAllLoading = createAction(CLEAR_ALL_LOADING);
const initailState = {};

const loading = handleActions(
  {
    [START_LOADING]: (state, action) => ({
      ...state,
      [action.payload]: true,
    }),
    [FINISH_LOADING]: (state, action) => ({
      ...state,
      [action.payload]: false,
    }),
    [CLEAR_LOADING]: (state, action) => ({
      ...state,
      [action.payload]: null,
    }),
    [CLEAR_ALL_LOADING]: (state, action) => ({}),
  },
  initailState,
);

export default loading;
