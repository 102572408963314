import { BrowserRouter } from 'react-router-dom';
import PopupContainer from './components/Popup/PopupContainer';
import AppRouter from './router/AppRouter';
import './assets/css/_popup.scss';

function App() {
  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <PopupContainer />
    </>
  );
}

export default App;
