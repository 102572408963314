import API from './API';

const api_get = async (url, params, setter) => {
  const response = await API.get(url, { params: params });
  response
    .then((res) => {
      if (res.data.msg.resultCode === '0000') {
        // console.log(res.data.data);
        setter(res.data.data);
      } else {
      }
    })
    .catch((error) => {});
};

const sync_api_get = async (url, params) => {
  try {
    const response = await API.get(url, { params: params });
    // console.log(response.data.data)
    if (response.status === 200 && response.data.msg.resultCode === '0000') {
      return {
        result: 'success',
        data: response.data.data,
      };
    } else {
      let message = '';
      switch (response.data.msg.resultCode) {
        case '1100':
          message = '필수값 누락';
          break;
        case '1200':
          message = 'Length 오류';
          break;
        case '1310':
          message = 'DataType 불일치 (Integer)';
          break;
        case '1320':
          message = '월간/주간 구분 파라미터 오류';
          break;
        case '1330':
          message = 'DataType 불일치 (Date)';
          break;
        case '1340':
          message = '숫자범위 오류';
          break;
        case '1350':
          message = '과목명 오류';
          break;
        case '2000':
          message = '데이터 없음';
          break;
        case '9000':
          message = '외부API 호출오류';
          break;
        case '9999':
          message = '시스템 오류, 기타 오류';
          break;
        default: {
          message = '알 수 없는 오류입니다. [' + response.data.msg.resultCode + ']';
        }
      }

      console.log('url : ', url);
      console.log('error : ', message);

      return {
        resultCode: response.data.msg.resultCode,
        result: 'fail',
        message: message,
      };
    }
  } catch (error) {
    console.log('url : ', url);
    console.log('error : ', error);

    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK' || error.response?.status === 408) {
      // alert("인터넷 연결에 실패했습니다.");
      return {
        result: 'fail',
        message: '인터넷 연결에 실패했습니다.',
      };
    } else {
      return {
        result: 'fail',
        message: error.message,
      };
    }
  }
};

// homeload
export const getLrnTypeDetail = (params) => {
  // STUD-LT-005 학습분석 상세
  return sync_api_get('/stud/admin/getLrnTypeDetail', params);
};

export const getLrnTypeHistory = (params) => {
  // STUD-LT-006 학습유형 내역
  return sync_api_get('/stud/admin/getLrnTypeHistory', params);
};

//common
export const getStudInfo = ({ token }) => {
  const url = `/stud/getStudInfo`;
  return API.post(url, { token });
};

export const getStudInfoFromP = ({ p }) => {
  const url = `/stud/getStudInfo?p=${p}`;
  return API.get(url);
};

//Challenge
export const getChRewardStt = (p) => {
  // const pCheck = p || 'q7lqHKEuIhdKwaZIW/TrFQ==';
  const url = `/admin-chl/getChRewardStt?p=${p}`;
  return API.get(url);
  // return API.get('stud-chl/getChMetaphorHistory', {p, startYyyyMm, endYyyyMm});
};

export const getChStepUpStt = (p) => {
  //const pCheck = p || 'J2UD7GbaEs4dGBawheoXHA==';
  const url = `/admin-chl/getChStepUpMisStt?p=${p}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getChMetaphorObjectStt', {p, startYyyyMm, endYyyyMm});
};

export const getChHabitMisStt = (p) => {
  //const pCheck = p || 'J2UD7GbaEs4dGBawheoXHA==';
  const url = `/admin-chl/getChHabitMisStt?p=${p}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getChHabitMissionInfo', {p});
};

export const getChMetaphorHistory = ({ p, yyyy }) => {
  //const pCheck = p || 'J2UD7GbaEs4dGBawheoXHA==';
  const url = `/admin-chl/getChMetaphorHistory?p=${p}&yyyy=${yyyy}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getStepUpMissionInfo', {p, startYyyyMm, endYyyyMm});
};

export const getChHabitMisHistory = ({ p, yyyymm }) => {
  //const pCheck = p || 'J2UD7GbaEs4dGBawheoXHA==';
  const url = `/admin-chl/getChHabitMisHistory?p=${p}&yyyymm=${yyyymm}`;
  return API.get(url);
  // return sync_api_get('stud-chl/getStepUpMissionInfo', {p, startYyyyMm, endYyyyMm});
};

export const getChStepUpMisHistory = ({ p, yyyy }) => {
  const url = `/admin-chl/getChStepUpMisHistory?p=${p}&yyyy=${yyyy}`;
  return API.get(url);
};

export const regAdminCompleteMission = ({ p, chCd, yyyymmdd }) => {
  const dateCheck = yyyymmdd || '';
  const params = {
    p,
    chCd,
    yyyymmdd: dateCheck,
  };
  const url = `/admin-chl/regAdminCompleteMission`;
  return API.get(url, { params });
};
