import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const ProtectedRoute = ({ children }) => {
  const [searchParams] = useSearchParams();
  const p = searchParams.get('p');
  console.log('children : ', children);

  return <>{p ? React.cloneElement(children, { p }) : <Navigate to="/" />}</>;
};

export default ProtectedRoute;
